import { api } from '@moveup/app-core';

const store = {
	actions: {
		patchUser({ commit }, { id, ...params }) {
			return api.patch(`users/${id}`, params).then(result => {
				if (result.value) commit('setUser', result.value);
				return result;
			});
		},

		getUsers(_, params) {
			return api.get('users', params);
		},

		getUser(_, id) {
			return api.get(`users/${id}`);
		},

		deleteUsers(_, params) {
			return api.del('users', params);
		},

		createUser(_, params) {
			return api.post('users', params);
		},

		updateUser(_, { _id: id, ...params }) {
			return api.patch(`users/${id}`, params);
		},

		patchApplication({ commit }, { id, ...params }) {
			return api.patch(`applications/${id}`, params).then(result => {
				if (result.value) commit('setApplication', result.value);
				return result;
			});
		},

		getApplications(_, params) {
			return api.get('applications', params);
		},

		getApplication(_, id) {
			return api.get(`applications/${id}`);
		},

		deleteApplications(_, params) {
			return api.del('applications', params);
		},

		createApplication(_, params) {
			return api.post('applications', params);
		},

		updateApplication(_, { _id, ...params }) {
			return api.patch(`applications/${_id}`, params);
		},

		getScopes(_, params) {
			return api.get('scopes', params);
		},

		getOrganizations(_, params) {
			return api.get('organizations', params);
		},

		getLanguages(_, params) {
			return api.get('languages', params);
		},
	},
};

export default store;
